/**
 * Created by Zura Jijavadze on 17/05/2018.
 */

function isMobMode() {
	return window.innerWidth <= 1200;
}

$(function () {




	//header slider
	$('header .slider').slick({
		dots: true,
		infinite: true,
		speed: 500,
		fade: true,
		cssEase: 'linear'
	});

	//in-page slider
	$('.content-panel .slider-wrapper .slider').slick({
		autoplay: true,
		dots: true,
		infinite: true,
		speed: 500,
		fade: true,
		cssEase: 'linear'
	});

	$('.projects-slider .slider-outer .slider').slick({
		dots: false,
		infinite: false,
		speed: 300,
		slidesToShow: 5,
		slidesToScroll: 5,
		arrows: true,
		nextArrow: $('.slider-buttons .sl-next'),
		prevArrow: $('.slider-buttons .sl-prev'),
		responsive: [
			{
				breakpoint: 1600,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4,
					infinite: true
				}
			},
			{
				breakpoint: 1300,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			},
			{
				breakpoint: 920,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

	$('.projects-slider .slider-filter li').click(function (e) {
		e.preventDefault();

		//remove all active filters
		$(this).addClass('active').siblings("li").removeClass('active');


		//get filter value
		let filterVal = $(this).attr('data-filter');

		let slider = $('.projects-slider .slider-outer .slider');

		//unFilter slick
		slider.slick('slickUnfilter');

		//remove all filters first
		$('.projects-slider .slider-outer .slider .project-item.hide-slide').removeClass('hide-slide');

		//apply new filter
		if (filterVal != "all") {
			$('.projects-slider .slider-outer .slider .project-item').each(function (i, e) {
				if ($(e).attr('data-status') != filterVal) {
					$(e).addClass('hide-slide');
				}
			});


			//filter slick
			slider.slick('slickFilter', ':not(.hide-slide)');
		}
	});


	//currency converter
	$('input.currency').lc_switch('₾', '$');

	//currency switch event
	$('body').delegate('.currency', 'lcs-statuschange', function () {
		var status = ($(this).is(':checked')) ? 'GEL' : 'USD';

		$.ajax({
			url: APP_LANG + '/data/set-currency/',
			type: 'POST',
			dataType: 'JSON',
			data: {
				currency: status,
				url: location.pathname
			},
			success: function (data) {
				if (data.res === 'yes') {
					if (typeof data.items !== "undefined") {
						location.href = data.items;
					}
					else {
						location.reload();
					}
				}
			}
		});

	});

	//safari ios only
	var ua = window.navigator.userAgent;
	var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
	var webkit = !!ua.match(/WebKit/i);
	var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);


	//mobile menu
	$('.hamburger').on("touchstart", function () {
		if ($(this).is('.is-active')) {
			//close menu
			$('html').removeClass('menu-open safari-only');
			$('.top-group').removeClass('active');
			$(this).removeClass('is-active');
		}
		else {
			//open menu
			$('html').addClass('menu-open');
			$('.top-group').addClass('active');
			$(this).addClass('is-active');

			if (iOSSafari) {
				$('html').addClass('safari-only');
			}
		}
	});


	//user login
	$('.login-btn').click(function (e) {
		e.preventDefault();
		$('#user-login').modal();
	});

	//auth form
	$('#auth-form').submit(function (e) {
		e.preventDefault();

		let sData = {
			username: $('#username').val(),
			password: $('#password').val()
		};

		$.ajax({
			url: APP_LANG + '/data/customer-auth/',
			type: 'POST',
			dataType: 'JSON',
			data: sData,
			success: function (data) {
				if (data.res == "yes") {
					//redirect to profile
					$('#user-login').modal('hide');
					location.href = APP_LANG + "/customer/";
				}
				else {
					//show error
					$('#username, #password').addClass('has-error');
					$('#login-error').removeClass('hidden');
				}
			}
		});

	});

	//user logout
	$('#logout-btn').click(function(e) {
		e.preventDefault();
		$.ajax({
			url: APP_LANG + "/data/customer-logout/",
			success: function () {
				location.href = APP_LANG + "/";
			}
		});
	});

});